<template>
  <div :class="['header-wrap', { sticky: isSticky }]">
    <!-- Top Header -->
    <div class="header-top" :class="{ open: open }">
      <button type="button" class="close-sidebar" @click="open = !open">
        <i class="ri-close-fill"></i>
      </button>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-8 col-md-9">
            <div class="header-top-left">
              <ul class="contact-info list-style">
                <li>
                  <i class="flaticon-call"></i>
                  <a href="tel:+237670729946">(+237) 670 72 99 46</a>
                </li>
                <li>
                  <i class="flaticon-email-1"></i>
                  <a href="mailto:info@netwalletpay.com">info@netwalletpay.com</a>
                </li>
                <li>
                  <i class="flaticon-pin"></i>
                  <p>
                    Rue 1761
                    Derriere le palais des congres
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-lg-4 col-md-3">
            <div class="header-top-right">
              <ul class="header-top-menu list-style">
                <li><router-link to="/contact">Support</router-link></li>
                <li><router-link to="/contact">Help</router-link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Top Header -->

    <!-- Navbar -->
    <div class="header-bottom">
      <div class="container">
        <nav class="navbar navbar-expand-md navbar-light">
          <router-link class="navbar-brand" to="/">
            <img class="logo-light" src="../../assets/images/logo.jpg" alt="logo" width="200" />
            <img class="logo-dark" src="../../assets/images/logo.jpg" alt="logo" width="200" />
          </router-link>

          <div class="collapse navbar-collapse" :class="{ show: active }">
            <ul class="navbar-nav ms-auto">
              <li class="nav-item">
                <router-link to="/" class="nav-link">Home</router-link>
              </li>

              <li class="nav-item">
                <router-link to="/about" class="nav-link">About</router-link>
              </li>

              <li class="nav-item has-dropdown">
                <a href="#" class="nav-link">
                  Services
                  <i class="ri-arrow-down-s-line"></i>
                </a>
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <router-link to="/services" class="nav-link">Features</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/service-details" class="nav-link">Services</router-link>
                  </li>

                </ul>
              </li>

              <li class="nav-item">
                <router-link to="/contact" class="nav-link">Contact</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/pricing" class="nav-link">Our Pricing</router-link>
              </li>
              <li class="nav-item">
                <a href="https://docs.netwalletpay.com" target="_blank" class="nav-link">Documentation</a>
              </li>
            </ul>

            <div class="others-options d-flex align-items-cente">
              <!-- <button class="searchbtn" type="button" @click="search = !search" :aria-pressed="search ? 'true' : 'false'"
                v-bind:class="{ search: button_search_state }" v-on:click="button_search_state = !button_search_state">
                <i class="flaticon-search"></i>
              </button>
              <div class="searchbox">
                <input type="text" placeholder="Search" />
                <button type="button">
                  <i class="flaticon-search"></i>
                </button>
              </div> -->
              <div class="header-btn">
                <router-link to="/login" class="btn style1">Login Now</router-link>
              </div>
            </div>
          </div>

          <div class="mobile-bar-wrap">
            <div class="mobile-sidebar" @click="open = !open" :aria-pressed="open ? 'true' : 'false'"
              v-bind:class="{ open: button_open_state }" v-on:click="button_open_state = !button_open_state">
              <i class="ri-menu-4-line"></i>
            </div>
            <button class="searchbtn" type="button" @click="search = !search" :aria-pressed="search ? 'true' : 'false'"
              v-bind:class="{ search: button_search_state }" v-on:click="button_search_state = !button_search_state">
              <i class="flaticon-search"></i>
            </button>
            <div class="navbar-toggler mobile-menu xl-none" @click="active = !active"
              :aria-pressed="active ? 'true' : 'false'" v-bind:class="{ active: button_active_state }"
              v-on:click="button_active_state = !button_active_state">
              <a href="javascript:void(0);">
                <i class="ri-menu-line"></i>
                <i class="ri-close-line"></i>
              </a>
            </div>
          </div>
        </nav>
      </div>

      <!-- Search Modal -->
      <div class="search-area" :class="{ search: search }">
        <div class="container">
          <form @submit.prevent>
            <div class="form-group">
              <input type="search" placeholder="Search Here" autofocus />
            </div>
          </form>
          <button type="button" class="close-searchbox" @click="search = !search">
            <i class="ri-close-line"></i>
          </button>
        </div>
      </div>
    </div>
    <!-- End Navbar -->
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data: () => ({
    isSticky: false,
    active: false,
    button_active_state: false,
    search: false,
    button_search_state: false,
    open: false,
    button_open_state: false,
  }),
  mounted() {
    const that = this;
    window.addEventListener("scroll", () => {
      let scrollPos = window.scrollY;
      if (scrollPos >= 100) {
        that.isSticky = true;
      } else {
        that.isSticky = false;
      }
    });
  },
};
</script>
